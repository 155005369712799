<template>
<section class="pricing">
    <div class="banner">
        <div class="container has-text-centered">
            <p style="padding-bottom: 10px;"><img src="../assets/lock.svg" width="55.5px" height="55.5px"></p>
            <h1 class="title">
                Password-less Encryption Done Right.
            </h1>
            <h2 class="subtitle">
                Encryption Simplified
            </h2>
        </div>
    </div>
    <div class="pricing-table">
        <div class="pricing-option">
            <h1>Free</h1>
            <hr />
            <p>Up to 3 contacts</p>
            <hr />
            <div class="price">
                <div class="numb">
                    <span class="price">0 <b>$</b></span> per month
                    <br>
                    <p class="is-size-7">Forever</p>
                </div>
            </div>
            <div class="butt">
                <router-link :to='{ path: this.link + "0" }'>
                    <a href="#" class="button">Select Plan</a>
                </router-link>
            </div>
        </div>
        
        <div class="pricing-option default-option">
            <h1>Standard</h1>
            <hr />
            <p>Up to 100 contacts</p>
            <hr />
            <div class="price">
                <div class="numb">
                    <span class="price">1 <b>$</b></span> per month
                    <br>
                    <p class="is-size-7">charged annually</p>
                </div>
            </div>
            <div class="butt">
                <router-link :to='{ path: this.link + "1" }'>
                    <a href="#" class="button">Select Plan</a>
                </router-link>
            </div>
        </div>
        
        <div class="pricing-option">
            <h1>Pro</h1>
            <hr />
            <p>Up to 1000 contacts</p>
            <hr />
            <div class="price">
                <div class="numb">
                    <span class="price">2<b>$</b></span> per month
                    <br>
                    <p class="is-size-7">charged annually</p>
                </div>
                <div class="butt">
                    <router-link :to='{ path: this.link + "2" }'>
                        <a href="#" class="button">Select Plan</a>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
  name: 'pricing',
  data() {
    return {
        link: "/beta/"
    }
  },
  computed: {
      loggedIn() {
        console.log("Login State: " + this.$store.state.auth.status.loggedIn);
        return this.$store.state.auth.status.loggedIn;
      },
  },
  created() {
      if (this.loggedIn) {
          this.link = "/dashboard/profile/"
      } 
  }
}
</script>
<style lang="scss" scoped>
// fonts
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:400,300,300italic,400italic,600,700,600italic,700italic);

// demo
body {
    font-family: "Josefin Sans", sans-serif;
    line-height: 1;
    padding: 20px;
    height: 80%;
    background: #eeeeee;
    background-color: #cccccc;
}

// variables
$icon-size: 3rem;
$icon-color: #D8D8D8;
$main-color: #aac734;

h1 {
    //color: $main-color;
}

.banner {
    background: $main-color;
    padding-bottom: 75px;
}

.pricing {
    width: 100%;
    text-align: center;
    padding-top: 45px;
}

.container {
    padding-top: 60px;
    padding-left: 20%;
    padding-right: 20%;
    width: 100%;
}

// Pricing table
.pricing-table {
    display: inline-block;
    background: #ffffff;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 20%;
    padding-right: 20%;
    .default-option {
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        transform: scale(1.04);
    }    
    .pricing-option {
        width: 32%;
        background:#ffffff;
        float: left;
        padding: 2%;
        text-align: center;
        transition: all .3s ease-in-out;
        &:nth-child(even) {
            margin: 0 1%;
        }
        &:hover {
            cursor: pointer;
            box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
            transform: scale(1.04);
            i, h1, span, b {
                color: $main-color;
                font-weight: bold;
            }
        }
        .butt {
                opacity: 1 !important;
                visibility: visible !important;
                a.button {
                    transform: translateY(0px) !important;
                }
            }
        hr {
            border:none;
            border-bottom: 1px solid #F0F0F0;
        }
        i {
            font-size: $icon-size;
            color: $icon-color;
            transition: all .3s ease-in-out;
        }
        h1 {
            margin: 10px 0;
            color: #212121;
            transition: all .3s ease-in-out;
        }
        p {
            color: #999;
            padding: 0 10px;
            line-height: 1.3;
        }
        .price {
            position: relative;
            background: #ffffff;
            .numb {
                span.price {
                    font-size: 2rem;
                    text-transform: uppercase;
                    margin-top: 20px;
                    display: block;
                    font-weight: 700;
                    position: relative;
                    b {
                        position: absolute;
                        font-size: 1rem;
                        margin-left: 2px;
                        font-weight: 600;
                    }
                }
            }
        }
        
        .butt {
                padding-top: 20px;
                opacity: 0;
                visibility: visible;
                transition: all .3s ease-in-out;
                a.button {
                    background: #ffffff;
                    color: $main-color;
                    border-color: $main-color;
                    &:hover {
                        background: $main-color;
                        color: white;
                    }
                }
            }
    }
}


// Responsive
@media screen and (max-width: 600px) {
    .pricing-table {
        .pricing-option {
            padding: 5%;
            width: 90%;
            &:nth-child(even) {
                margin: 30px 0 !important;
            }
        }
    }
}
</style>